import React from "react"
import { graphql, Link } from 'gatsby'
import { motion } from "framer-motion"
import { fadeSlow, revealInOut } from "../helpers/transitionHelper"
import {Helmet} from "react-helmet";

import urlFor from '../helpers/imageBuilder'

function padLeft(nr, n, str) {
    return Array(n-String(nr).length+1).join(str||'0')+nr;
}

const IndexPage = ({data, childAnimationDelay}) => {
  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >    
      <Helmet>
        <title>Blog - {data.sanitySiteSettings.title}</title>
      </Helmet>
      <motion.div variants={fadeSlow}>
         <motion.div variants={{
          enter: { transition: { 
            staggerChildren: .1,
            delayChildren: childAnimationDelay
          }}
        }}>
          <title>Blog</title>
          <motion.h1 variants={revealInOut} className="page-title">Blog</motion.h1>
          {/* <motion.div variants={lineVariants} className="line"></motion.div> */}
          <motion.div 
            className="blog-list" 
            variants={{
              enter: { transition: { 
                staggerChildren: .05,
                delayChildren: .2
              }}
            }}>
            {data.allSanityBlog.nodes.map((blog, i) => (
              <Link to={`/blog/${blog.slug.current}`}>
                <motion.div className="blog-list-row" variants={revealInOut}>
                  <div className="blog-index">{padLeft(i + 1, 2)}</div>
                  <div className="blog-date">{blog.date}</div>
                  <img src={urlFor(blog._rawFeaturedImage).width(400).url()} alt=""/>
                  <div className="blog-title">{blog.title}</div>
                  <div className="blog-arrow">
                    <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M13.9142 0.585785L27.8284 14.5L13.9142 28.4142L11.0858 25.5858L20.1716 16.5H0V12.5H20.1716L11.0858 3.41421L13.9142 0.585785Z" fill="black"/>
                    </svg>
                  </div>
                </motion.div>
              </Link>
            ))}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default IndexPage

export const query = graphql`
  query AllBlogQuery {
    sanitySiteSettings {
      title
    }
    allSanityBlog(sort: {fields: date, order: ASC}) {
      nodes {
        title
        slug {
          current
        }
        date
        _rawFeaturedImage
      }
    }
  }
`